@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-sb {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hide-sb::-webkit-scrollbar {
    display: none;
}
